import React, { useState, useEffect } from 'react'

import loadable from '@loadable/component'

const PopupLink = loadable(() => import('./PopupLink'))

const CheckList = ({ title, items, type, limit }) => {
  const [display, setDisplay] = useState(null)

  const toggleDisplay = () =>
    display === limit ? setDisplay(items.length) : setDisplay(limit)

  useEffect(() => {
    limit ? setDisplay(limit) : setDisplay(items.length)
  }, [limit])

  return (
    <div className="py-8 border-t border-gray-200 border-solid lg:py-16">
      {title && <h3 className="mb-4 text-lg font-bold">{title}</h3>}
      <ul className="checkList" data-type={type}>
        {items.map((item, index) => (
          <li
            key={item._id}
            className="pb-4 text-sm"
            aria-hidden={!(display === null || index < display)}
          >
            <div className="flex-1">
              {type === 'short' ? (
                <>
                  {item.title && (
                    <p className="leading-tight text-gray-800">{item.title}</p>
                  )}
                </>
              ) : (
                <>
                  {item.title && <h4 className="font-bold">{item.title}</h4>}
                  {item.description && (
                    <p className="leading-6 text-gray-800 text-16">{item.description}</p>
                  )}
                  {item.popupLink && (
                    <PopupLink
                      linkLabel={item.popupLink.linkLabel}
                      popup={item.popupLink.popup}
                      classes="text-sm"
                    />
                  )}
                </>
              )}
            </div>
          </li>
        ))}
      </ul>
      {display !== null && limit < items.length && (
        <button
          className="mt-2 font-bold text-blue-500"
          type="button"
          onClick={toggleDisplay}
        >
          <span
            className={`inline-block transform duration-300 mr-2 ${
              display < items.length ? '' : 'rotate-45'
            }`}
          >
            +
          </span>
          {display < items.length
            ? `Show all ${title.toLowerCase()}`
            : `Show less`}
        </button>
      )}
    </div>
  )
}

export default CheckList
